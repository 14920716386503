<template lang="">
  <IAmOverlay :loading="loading">
    <BCard body-class="p-50">
      <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
        <IAmIcon
          icon="infoOutline"
          size="25"
          class="p-1 icon-card"
        />
        <span class="font-medium-3 m-0 fw-700">
          {{ $t('invoice.invoiceTickets.create.title') }}
        </span>
      </div>
      <ValidationObserver ref="refFormObserver">
        <InvoiceTicketForm
          :invoiceTicket.sync="invoiceTicketData"
          :isEditing="true"
        />

        <div class="d-flex-center gap-2 my-1">
          <BButton
            pill
            class="width-150"
            variant="outline-secondary"
            :to="{ name: 'apps-invoiceTicketList' }"
          >
            {{ $t('invoice.invoiceTickets.btn.back') }}
          </BButton>

          <BButton
            pill
            class="width-150"
            variant="primary"
            @click="onCreateHandle"
          >
            {{ $t('invoice.invoiceTickets.btn.create') }}
          </BButton>
        </div>
      </ValidationObserver>
    </BCard>
  </IAmOverlay>
</template>
<script>
import { ref } from '@vue/composition-api'
import { BButton, BCard } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'

import { apiInvoice } from '@/api'
import IAmOverlay from '@/components/IAmOverlay.vue'
import router from '@/router'
import { convertISODateTime } from '@/@core/utils/filter'

import formValidation from '@core/comp-functions/forms/form-validation'

import useToast from '@useToast'

import InvoiceTicketForm from './InvoiceTicketForm.vue'

export default {
  components: {
    InvoiceTicketForm,
    IAmOverlay,
    BCard,
    ValidationObserver,
    BButton,
  },
  setup() {
    const { refFormObserver } = formValidation()
    const { toastError, toastSuccess } = useToast()
    const loading = ref(false)

    const blankData = {
      bookingCode: '',
      ticketNumber: '',
      customerName: '',
      flightTrip: '',
      flightDate: convertISODateTime(new Date()).dateFilter,
      type: null,
      unitPrice: null,
      valueAddedTax: null,
      collectionFee: null,
    }
    const invoiceTicketData = ref()
    const resetData = () => {
      invoiceTicketData.value = cloneDeep(blankData)
    }
    resetData()

    async function onCreateHandle() {
      const valid = await refFormObserver.value.validate()
      if (valid) {
        loading.value = true
        const payload = {
          bookingCode: invoiceTicketData.value.bookingCode || undefined,
          ticketNumber: invoiceTicketData.value.ticketNumber || undefined,
          customerName: invoiceTicketData.value.customerName || undefined,
          flightTrip: invoiceTicketData.value.flightTrip || undefined,
          flightDate: invoiceTicketData.value.flightDate || undefined,
          type: invoiceTicketData.value.type,
          valueAddedTax: invoiceTicketData.value.valueAddedTax,
          unitPrice: Number(invoiceTicketData.value.unitPrice),
          collectionFee: Number(invoiceTicketData.value.collectionFee),
        }
        try {
          const res = await apiInvoice.createInvoiceTicket(payload)
          toastSuccess('Tạo vé thành công')
          if (res.id) {
            router.replace({ name: 'apps-invoiceTicketList' })
          }
        } catch (error) {
          toastError('Lỗi tạo')
        } finally {
          loading.value = false
        }
      }
    }
    return {
      invoiceTicketData,
      loading,
      refFormObserver,
      onCreateHandle,
    }
  },
}
</script>
<style lang="">

</style>
